<template>
    <div class="login-contain">
        <Navbar title="注册账号" />
        <div class="opt-area">
            <van-cell-group>
                <van-field
                    label="用户名"
                    v-model="piUserInfo.pi_username"
                    center
                    disabled
                    placeholder="请输入用户名"
                >
                </van-field>
                <van-field
                    label="密码"
                    v-model="authCode"
                    maxlength="10"
                    required
                    center
                    clearable
                    placeholder="请输入密码，建议8-20位，并有英文大小写和数字"
                    :error-message="errMsg"
                    @blur="checkPhone"
                >
                </van-field>
            </van-cell-group>
        </div>
        <div
            class="login-btn"
            @click="goHome"
        >
            <img
                src="../account/assets/img/login-btn.png"
                alt=""
            >
        </div>
    </div>
</template>

<script>
import Navbar from "../components/NavBar.vue";
import { Toast } from 'vant'
export default {
    components: {
        Navbar,
    },
    data() {
        return {
            logo: "",
            timing: 60,
            isSend: false,
            teleVal: "", // 手机号
            authCode: "", // 验证码
            piUserInfo: {}, // pi浏览器登录信息
            errMsg: "",
        };
    },
    mounted() {
        this.getLoginInfo();
    },
    methods: {
        checkPassword(pwd) {
            // 匹配密码中是否包含英文大小写字母和数字
            const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]+$/;
            if (!reg.test(pwd)) {
                return false;
            }
            // 检测密码长度是否在 8-20 个字符之间
            if (pwd.length < 8 || pwd.length > 20) {
                return false;
            }
            return true;
        },

        checkPhone() {
            let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]+$/;
            if (reg.test(this.authCode)) {
                this.errMsg = "";
                return true;
            } else {
                this.errMsg = "密码设置8-20位，并有英文大小写和数字";
                return false;
            }
        },
        async goHome() { 
            if (this.errMsg) return;
            if(!this.checkPassword(this.authCode)){
                this.$toast.fail('密码设置8-20位，并有英文大小写和数字')
                return
            }
            let res = await this.axios.post("/register", {
                ...this.piUserInfo,
                userName: this.piUserInfo.pi_username,
                password: this.authCode,
            });
            if (res.data.success) {
                this.$router.push("/");
            }
        },
        async getLoginInfo() {
            Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: "加载中..."
            });
            const scopes = ["username", "payments"];
            let authResult = await window.Pi.authenticate(
                scopes,
                this.onIncompletePaymentFound
            );
            Toast.clear()
            if (authResult.user.uid) {
                this.piUserInfo = {
                    pi_uuid: authResult.user.uid,
                    pi_username: authResult.user.username,
                };
            }
        },
        onIncompletePaymentFound(payment) {
            console.log("onIncompletePaymentFound", payment);
            return this.axios.post("/payments/incomplete", { payment });
        },
    },
};
</script>

<style lang="less" scoped>
@import "../account/assets/css/login.less";
</style>